#person-registration {
    background-color: white;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 8px;
}

.form-section {
    display: block;
    font-weight: 400;
    margin-bottom: 6px;
}

#person-registration form div {
    margin-top: 14px;
}

#person-registration form input[type='tel'] {
    border: none;
    outline: none;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding-left: 10px;
    font-size: 16px;
    /*border-top: 2px #b0b0b0 solid;
    border-left: 2px #b0b0b0 solid;*/
}

#person-registration form input[type='text'] {
    width: 100%;
    border: none;
    outline: none;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding-left: 10px;
    font-size: 16px;
    /*border-top: 2px #b0b0b0 solid;
    border-left: 2px #b0b0b0 solid;*/
}

#person-registration form textarea {
    width: 100%;
    border: none;
    outline: none;
    height: 80px;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 10px;
    font-size: 16px;
    resize: none;
    /*border-top: 2px #b0b0b0 solid;
    border-left: 2px #b0b0b0 solid;*/
}

#person-birth, #person-dead {
    width: 60px;
}

#person-register {
    border: none;
    background: rgb(93, 127, 222);
    width: 100%;
    height: 50px;
    border-radius: 8px;
    color: #eee;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

#person-registration .note {
    font-size: 14px;
    color: #444;
}

#insertEventForm {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 80px 100px;
    font-weight: 600;
}

#insertEventForm input[type='text'] {
    display: block;
    border: none;
    outline: none;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding-left: 10px;
    font-size: 16px;
}

#insertEventForm input[type='tel'] {
    border: none;
    outline: none;
    height: 40px;
    width: 58px;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding-left: 10px;
    font-size: 16px;
    margin-right: 5px;
}

#insertEventForm button {
    border: none;
    background: rgb(93, 127, 222);
    height: 40px;
    border-radius: 8px;
    color: #eee;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.inputDef[type="text"], .inputDef[type="password"] {
    display: block;
    border: none;
    outline: none;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding-left: 10px;
    font-size: 16px;
}

.buttonDef {
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    padding: 12px;
    border-radius: 5px;
    background: rgb(140, 215, 171);
    display: inline-block;
    font-weight: 500;
}

.loginForm {
    display: grid;
    gap: 10px;
    width: 100%;
}