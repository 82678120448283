.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.App-link {
  color: #61dafb;
}
